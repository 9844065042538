// Importing Dependencies
import React, { useState, useEffect } from "react";
import AwesomeLightbox from "react-awesome-lightbox";
import client from "../../client.js";
import { ReactComponent as ArrowRight } from "../../resources/images/arrowRight.svg";

// Importing Styles
import "react-awesome-lightbox/build/style.css";
import "./FeatureGallery.css";

const FeatureGallery = ({ FilterKeyword, ProjectName }) => {
  // =========================================== Ligtbox Logic ================================================

  //* Tab State 

  const [tab, setTab] = useState('before');
  //*Logic States
  const [isLightbox, setIsLightBox] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [whichGallery, setWhichGallery] = useState("Before");

  //*Images States
  const [BeforePhotos, setBeforePhotos] = useState([]);
  const [AfterPhotos, setAfterPhotos] = useState([]);

  //* Extracting Urls for Lightbox
  const [BeforeLightBoxImages, setBeforeLightBoxImages] = useState([]);
  const [AfterLightBoxImages, setAfterLightBoxImages] = useState([]);

  //*Fetching Images
  useEffect(() => {
    //* Fetching Before Images
    client
      .fetch(
        `*[_type == 'simpleProjects' && ProjectName == '${ProjectName}']{
                BeforeImages[] {
                  Image {
                    asset-> {url}
                  },
                  ImageType
                }
            } `
      )
      .then((responce) => {
        setBeforePhotos(responce[0].BeforeImages);
      });

    //*Fetching After Images
    client
      .fetch(
        `*[_type == 'simpleProjects' && ProjectName == '${ProjectName}']{
                AfterImages[] {
                  Image {
                    asset-> {url}
                  },
                  ImageType
                }
            } `
      )
      .then((responce) => {
        setAfterPhotos(responce[0].AfterImages);
      });
  }, [ProjectName]);

  //*Sorting the images
  useEffect(() => {
    setBeforeLightBoxImages(
      BeforePhotos.map((element) => element.Image.asset.url)
    );
    setAfterLightBoxImages(
      AfterPhotos.map((element) => element.Image.asset.url)
    );
  }, [BeforePhotos, AfterPhotos]);

  //* Extracting Url's finished

  function OpenLightBox(InputIndex, Galleryname) {
    setCurrentImageIndex(InputIndex);
    setWhichGallery(Galleryname);
    setIsLightBox(true);
  }

  function CloseLightBOx() {
    setIsLightBox(false);
  }

  // =============================================== Lightbox Logic Ended ==========================================

  const Bphotos1 = BeforePhotos.filter((item, index) => index % 3 === 0);
  const Bphotos2 = BeforePhotos.filter((item, index) => index % 3 === 1);
  const Bphotos3 = BeforePhotos.filter((item, index) => index % 3 === 2);
  // =====================================   Before Images Ended ===============================

  const Aphotos1 = AfterPhotos.filter((item, index) => index % 3 === 0);
  const Aphotos2 = AfterPhotos.filter((item, index) => index % 3 === 1);
  const Aphotos3 = AfterPhotos.filter((item, index) => index % 3 === 2);

  return (
    <React.Fragment>
      {/* =========================================== Lightbox ===================================== */}
      {isLightbox && (
        <AwesomeLightbox
          images={
            whichGallery === "before"
              ? BeforeLightBoxImages
              : AfterLightBoxImages
          }
          startIndex={currentImageIndex}
          onClose={() => CloseLightBOx()}
        />
      )}
      {/* =========================================== Lightbox Ended ========================================== */}



      {/* Before / After & Before /After */}
      <div className="before_after ">
        <div onClick={()=>{setTab('before')}} className={`${tab==='before'?'before_after_active':''}`}>
          <button>
            Before
            <ArrowRight className="ArrowRightGallery" />
          </button>
        </div>

        <div onClick={()=>{setTab('after')}} className={`${tab==='after'?'before_after_active':''}`}>
          <button>
            After
            <ArrowRight className="ArrowRightGallery" />
          </button>
        </div>

        <div onClick={()=>{setTab('before_after')}} className={`${tab==='before_after'?'before_after_active':''}`}>
          <button>
            Before/After
            <ArrowRight className="ArrowRightGallery" />
          </button>
        </div>
      </div>


          {/* Before / After / Before_AFter Images */}



            {/* ========================Before Images =-===================== */}

            {
              tab == 'before'? 
      <div className="Gallery container-fluid">


        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3">
          <div className="col image-gallery ">
            {Bphotos1.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {(FilterKeyword === item.ImageType ||
                    FilterKeyword === "all") &&
                  BeforePhotos.length > 0 ? (
                    <div key={index} className="image-card">
                      <img
                        loading="lazy"
                        key={index * 3}
                        className=""
                        src={item.Image.asset.url}
                        alt="Before"
                        onClick={() => OpenLightBox(index * 3, "before")}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
          <div className="col image-gallery ">
            {Bphotos2.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {(FilterKeyword === item.ImageType ||
                    FilterKeyword === "all") &&
                  BeforePhotos.length > 0 ? (
                    <div key={index} className="image-card">
                      <img
                        loading="lazy"
                        key={index * 3 + 1}
                        className=""
                        src={item.Image.asset.url}
                        alt="Before"
                        onClick={() => OpenLightBox(index * 3 + 1, "before")}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
          <div className="col image-gallery ">
            {Bphotos3.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {(FilterKeyword === item.ImageType ||
                    FilterKeyword === "all") &&
                  BeforePhotos.length > 0 ? (
                    <div key={index} className="image-card">
                      <img
                        loading="lazy"
                        key={index * 3 + 2}
                        className=""
                        src={item.Image.asset.url}
                        alt="Before"
                        onClick={() => OpenLightBox(index * 3 + 2, "before")}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>

      :
      <></>

}




      {/* ============================================= After Gallery =============================== */}

      {
        tab=='after'?
      <div className="Gallery container-fluid">
        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3">
          <div className="col image-gallery ">
            {Aphotos1.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {(FilterKeyword === item.ImageType ||
                    FilterKeyword === "all") &&
                  BeforePhotos.length > 0 ? (
                    <div key={index} className="image-card">
                      <img
                        loading="lazy"
                        key={index * 3}
                        className=""
                        src={item.Image.asset.url}
                        alt="Before"
                        onClick={() => OpenLightBox(index * 3, "after")}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
          <div className="col image-gallery">
            {Aphotos2.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {(FilterKeyword === item.ImageType ||
                    FilterKeyword === "all") &&
                  BeforePhotos.length > 0 ? (
                    <div key={index} className="image-card">
                      <img
                        loading="lazy"
                        key={index * 3 + 1}
                        className=""
                        src={item.Image.asset.url}
                        alt="Before"
                        onClick={() => OpenLightBox(index * 3 + 1, "before")}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
          <div className="col image-gallery">
            {Aphotos3.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {(FilterKeyword === item.ImageType ||
                    FilterKeyword === "all") &&
                  BeforePhotos.length > 0 ? (
                    <div key={index} className="image-card">
                      <img
                        loading="lazy"
                        key={index * 3 + 2}
                        className=""
                        src={item.Image.asset.url}
                        alt="Before"
                        onClick={() => OpenLightBox(index * 3 + 2, "before")}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>

      :
      <></>

}


      {/* ======================================== Gallery Started =============================================== */}
    </React.Fragment>
  );
};

export default FeatureGallery;
