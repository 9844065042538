
// Importing Components 
// import Benefits from '../comp/Benefits/Benefits';
// import Head from '../comp/Header/Head';
import HomeProjectCards from '../comp/HomeProjectCards/HomeProjectCards';
import FAQ from '../comp/FAQ/Faq.jsx'
// import Reviews from '../comp/Reviews/Reviews.jsx'
// import Form from '../comp/Form/Form.jsx'


const Home = (props) => {
  return (
    <div>
        
        {/* <Head /> */}

        <div className='project_header_div'>

        <h1 className='projectHeading'>Our  Projects</h1>
        <p className='projectHeading_description'>We have presented our work in a easy to access gallery here for our customers.
          All our projects here are 
        </p>
        <button style={{padding : '10px 20px', borderRadius : '40px' , fontWeight : '500' ,background : 'black', color : 'white' }} className=''>Call +1 778-996-3721</button>
        </div>


        <HomeProjectCards ProjectsList={props.ProjectsList} />
        {/* <Benefits /> */}
        <FAQ />
        {/* <Reviews /> */}
        {/* <Form /> */}

    </div>
  )
}

export default Home